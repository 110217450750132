export interface AnnouncementOptions {
  live?: "assertive" | "polite"
  timeout?: number
}

interface ConfirmModaldata {
  title?: string
  text?: string
  confirmLabel?: string
  cancelLabel?: string
}

interface Notification {
  message: string
  type: MessageType
}

export const useNotificationStore = defineStore("notifications", () => {
  const fadeOutTimeout = 5000

  const announcementOptionsDefault: AnnouncementOptions = {
    live: "assertive",
    timeout: fadeOutTimeout,
  }

  // Announcement are aria-live messages for the "~/components/Announcer.vue" component which is loaded on the default layout
  const announcement = ref("")
  const announcementOptions: Ref<AnnouncementOptions> = ref(
    announcementOptionsDefault,
  )

  const notifications: Ref<Notification[]> = ref([])
  const confirmModalData: Ref<ConfirmModaldata> = ref({})
  const modalConfirmAction = ref<(() => void) | null>(null)
  const modalCancelAction = ref<(() => void) | null>(null)

  const deleteNotification = (index: number) => {
    notifications.value.splice(index, 1)
  }

  const add = (notification: Notification) => {
    notifications.value.push(notification)
    if (["success", "info"].includes(notification.type)) {
      setTimeout(() => {
        deleteNotification(notifications.value.indexOf(notification))
      }, fadeOutTimeout)
    }
  }

  const announce = (message: string, options: AnnouncementOptions = {}) => {
    announcement.value = message
    if (Object.keys(options).length > 0) {
      announcementOptions.value = options
    }
    setTimeout(() => {
      announcement.value = ""
      if (Object.keys(options).length > 0) {
        announcementOptions.value = announcementOptionsDefault
      }
    }, options.timeout || fadeOutTimeout)
  }

  const closeConfirmModal = () => {}

  const confirmAction = (data: ConfirmModaldata) => {
    confirmModalData.value = data
    return new Promise((resolve) => {
      modalConfirmAction.value = () => {
        resolve(true)
      }
      modalCancelAction.value = () => {
        closeConfirmModal()
        resolve(false)
      }
    })
  }

  return {
    add,
    closeConfirmModal,
    confirmAction,
    announce,
    announcement,
    announcementOptions,
    notifications,
    confirmModalData,
    modalConfirmAction,
    modalCancelAction,
  }
})
